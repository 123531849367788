import React from "react";
import { lightning_bolt } from "../assets/images/index";

const Loader = ({ msg }) => {
  return (
    <>
      <div className="loader--container">
        <div className="gearbox">
          <div className="gear one">
            <div className="gear-inner blue-dark">
              <div className="bar blue-dark"></div>
              <div className="bar blue-dark"></div>
              <div className="bar blue-dark"></div>
            </div>
          </div>
          <div className="gear two">
            <div className="gear-inner blue-dark">
              <div className="bar blue-dark"></div>
              <div className="bar blue-dark"></div>
              <div className="bar blue-dark"></div>
            </div>
          </div>
          <div className="gear four large">
            <img
              className="gear-bolt"
              src={lightning_bolt}
              alt="loader_lightning_bolt"
            />
            <div className="gear-inner blue-light">
              <div className="bar blue-light"></div>
              <div className="bar blue-light"></div>
              <div className="bar blue-light"></div>
              <div className="bar blue-light"></div>
              <div className="bar blue-light"></div>
              <div className="bar blue-light"></div>
            </div>
          </div>
        </div>
        <h1>{msg}...</h1>
      </div>
      <style jsx global>
        {`
          * {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }
          img.gear-bolt {
            position: absolute;
            z-index: 1;
            width: 55px;
            left: 21px;
            top: 8px;
            -webkit-animation: counter-clockwise 6s infinite linear;
            -moz-animation: counter-clockwise 6s infinite linear;
          }
          .blue-dark {
            background: #2d1f54;
          }
          .blue-light {
            background: #0196aa;
          }
          .loader--container {
            background-color: #fafafa;
            z-index: 999;
            flex-direction: column;
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
          }
          .gearbox {
            height: 130px;
            width: 250px;
            position: relative;
          }
          .gear {
            position: absolute;
            height: 50px;
            width: 50px;
          }
          .gear.large {
            height: 100px;
            width: 100px;
            -moz-border-radius: 60px;
            -webkit-border-radius: 60px;
            border-radius: 60px;
          }
          .gear.one {
            top: 21px;
            left: 21px;
          }
          .gear.two {
            top: 61px;
            left: 60px;
          }
          .gear.four {
            top: 22px;
            left: 116px;
          }
          .gear-inner {
            position: relative;
            height: 100%;
            width: 100%;
            -webkit-animation-iteration-count: infinite;
            -moz-animation-iteration-count: infinite;
            -moz-border-radius: 30px;
            -webkit-border-radius: 30px;
            border-radius: 30px;
            border: 1px solid rgba(255, 255, 255, 0.1);
          }
          .large .gear-inner {
            -moz-border-radius: 60px;
            -webkit-border-radius: 60px;
            border-radius: 60px;
          }
          .gear.one .gear-inner {
            -webkit-animation: counter-clockwise 3s infinite linear;
            -moz-animation: counter-clockwise 3s infinite linear;
          }
          .gear.two .gear-inner {
            -webkit-animation: clockwise 3s infinite linear;
            -moz-animation: clockwise 3s infinite linear;
          }
          .gear.four .gear-inner {
            -webkit-animation: counter-clockwise 6s infinite linear;
            -moz-animation: counter-clockwise 6s infinite linear;
          }
          .gear-inner .bar {
            height: 14px;
            width: 60px;
            position: absolute;
            left: 60%;
            margin-left: -35px;
            top: 40%;
            margin-top: -2px;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            border-right: 1px solid rgba(255, 255, 255, 0.1);
          }
          .large .gear-inner .bar {
            margin-left: -66px;
            width: 112px;
            top: 45%;
          }
          .gear-inner .bar:nth-child(2) {
            -moz-transform: rotate(60deg);
            -ms-transform: rotate(60deg);
            -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
          }
          .gear-inner .bar:nth-child(3) {
            -moz-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
          }
          .gear-inner .bar:nth-child(4) {
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
          .gear-inner .bar:nth-child(5) {
            -moz-transform: rotate(30deg);
            -ms-transform: rotate(30deg);
            -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
          }
          .gear-inner .bar:nth-child(6) {
            -moz-transform: rotate(150deg);
            -ms-transform: rotate(150deg);
            -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
          }

          h1 {
            color: #2d1f54;
            font-size: 25px;
            margin-top: 0;
          }
          @-webkit-keyframes clockwise {
            0% {
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @-moz-keyframes clockwise {
            0% {
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @-webkit-keyframes counter-clockwise {
            0% {
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -moz-transform: rotate(-360deg);
              -ms-transform: rotate(-360deg);
              -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
            }
          }
          @-moz-keyframes counter-clockwise {
            0% {
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -moz-transform: rotate(-360deg);
              -ms-transform: rotate(-360deg);
              -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
            }
          }
        `}
      </style>
    </>
  );
};

export default Loader;
