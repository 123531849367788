import React, { Fragment } from "react";

const Footer = ({ fixture }) => {
  return (
    <Fragment>
      <div className="footer--container">
        <p>
          {fixture.text}
          <span className="mdi mdi-copyright"></span>
          {fixture.year}
        </p>
      </div>
      <style jsx>
        {`
          .footer--container {
            border-top: 1px solid #a2a2a2;
            padding: 20px;
            margin: 0 30px;
            display: flex;
            justify-content: center;
          }
          .footer--container span {
            margin: 0 5px;
          }
          .footer--container p {
            display: flex;
            margin: 0;
            color: #a2a2a2;
          }
          @media screen and (max-width: 576px) {
            .footer--container p {
              font-size: 12px;
            }
            .footer--container {
              padding: 20px 10px;
            }
          }
        `}
      </style>
    </Fragment>
  );
};
export default Footer;
