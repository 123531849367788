export const logo = require("./full_logo.png");
export const logo_text = require("./logo_text.png");
export const logo_icon = require("./logo_icon.png");
export const menu_background = require("./menu_background.jpg");
export const company = require("./company.jpg");
export const excavator = require("./home_excavator.png");
export const bridge = require("./home_bridge.png");
export const svg_logo = require("./logo_image.svg");
export const home_parallax = require("./home_parallax.jpg");
export const home_why_us = require("./why_us.png");
export const lightning_bolt = require("./lightning_bolt.png");
export const products_services1 = require("./products_services1.jpg");
export const products_services2 = require("./products_services2.jpg");
export const products_services3 = require("./products_services3.jpg");
export const products_services4 = require("./products_services4.jpg");
export const products_services5 = require("./products_services5.jpg");
export const products_services6 = require("./products_services6.jpg");
export const products_services7 = require("./products_services7.jpg");
export const products_services8 = require("./products_services8.jpg");
export const products_services9 = require("./products_services9.jpg");
export const contact_background = require("./contact_background.jpg");
export const products_services = {
  item0: products_services1,
  item1: products_services2,
  item2: products_services3,
  item3: products_services4,
  item4: products_services5,
  item5: products_services6,
  item6: products_services7,
  item7: products_services8,
  item8: products_services9,
};
