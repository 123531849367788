import React, { lazy, Suspense, useContext, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import "./assets/css/base.css";
import { LanguageContext } from "./containers/LanguageContext";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import home from "./fixtures/Home.json";
import contact from "./fixtures/Contact.json";
import header_footer from "./fixtures/HeaderFooter.json";
import products_services from "./fixtures/ProducstServices.json";
const Home = lazy(() => import("./containers/Home"));
const ProductsServices = lazy(() => import("./containers/ProductsServices"));
const Contact = lazy(() => import("./containers/Contact"));
const NoMatchPage = lazy(() => import("./containers/NoMatchPage"));

function App() {
  const { state } = useContext(LanguageContext);
  return (
    <Fragment>
      <header>
        <NavBar
          fixture={
            state.lang === "ES"
              ? header_footer.ES.header
              : header_footer.EN.header
          }
        />
      </header>
      <div className="app--main__content">
        <Suspense
          fallback={
            <Loader msg={`${state.lang === "ES" ? "Cargando" : "Loading"}`} />
          }
        >
          <Switch>
            <Route exact path={"/"}>
              <Home
                link={
                  state.lang === "ES"
                    ? header_footer.ES.header
                    : header_footer.EN.header
                }
                fixture={state.lang === "ES" ? home.ES : home.EN}
              />
            </Route>
            <Route path={"/(contacto|contact)/"}>
              <Contact
                fixture={state.lang === "ES" ? contact.ES : contact.EN}
              />
            </Route>
            <Route path={"/(productos-y-servicios|products-and-services)/"}>
              <ProductsServices
                fixture={
                  state.lang === "ES"
                    ? products_services.ES
                    : products_services.EN
                }
              />
            </Route>
            <Route component={NoMatchPage} />
          </Switch>
        </Suspense>
      </div>
      <Footer
        fixture={
          state.lang === "ES"
            ? header_footer.ES.footer
            : header_footer.EN.footer
        }
      />
      <style jsx>
        {`
          .app--main__content {
            display: flex;
            min-height: calc(100vh - 155px);
            max-width: 1600px;
            margin: 0 auto;
          }
          .notFixed .navbar--fixed {
            position: relative;
          }
          .notFixed .app--main__content > div {
            padding-top: 0;
          }
          .notFixed .app--main__content {
            min-height: calc(100vh - 155px);
          }
          @media screen and (max-width: 768px) {
            .app--main__content {
              padding-top: 75px;
              min-height: calc(100vh - 133px);
            }
          }
        `}
      </style>
    </Fragment>
  );
}

export default App;
